import React from 'react';
import { Scrollbars } from 'react-custom-scrollbars';
import { cartCrossIcon, cartDotedLine, } from '../../../assets/images/newImages';
import { RadioGroup, FormControlLabel, Radio, Paper, AppBar } from '@material-ui/core';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import { useState, useEffect } from 'react';
import CounterInput from '../../../components/counter';
import tip from '../../../environment/index.js';

function PayFully(props) {
    const { tip, setTip, restaurant, paymentValue, handlePaymentChange, closeCartModal, paymentMethodsObj, paymentBtn, classes, currentLanguage, totalAmount,restaurantSuccess } = props;
    const [value, setValue] = React.useState(2);
    const [tips, setTips] = useState([]);
    const [tipPercentage, setTipPercentage] = useState(0);
    const [tipAmount, setTipAmount] = useState(0);
    const [payableAmount, setPayAbleAmount] = React.useState(totalAmount);
    const { t, i18n } = useTranslation();

    const viewportHeight = window.innerHeight; //get the hight of visisble window 
    const modalhight = viewportHeight - 44; //40px minus 44 is used to minus the portion having logo in modal
    const modalHightDivision = modalhight - 100;
    const [activeTab, setActiveTab] = useState(0); // Track the active tab index

    const handleChanges = (event, newValue) => {
        setValue(newValue);
    };

    const handleTabClick = (index, item ) => {
        setTipPercentage(parseInt(item.replace('%','')));// convert the percentage to a number and set the tip percentage
        setActiveTab(index); // Set  the active tab when clicked
    };

    useEffect(() => {
        // Simulating async fetch
        setTips([{ percent: "1%" }, { percent: "2%" }, { percent: "3%" }, { percent: "4%" },{ percent: "5%" }, 
                { percent: "6%" }, { percent: "7%" }, { percent: "8%" },{ percent: "9%" },{ percent: "10%" }
        ]);
    },[]);

    useEffect(() => {
        const calculatedTip = (tipPercentage / 100) * totalAmount;
        console.log("calculatedTip",calculatedTip);
        setTip(calculatedTip)
        setTipAmount(calculatedTip)
        setPayAbleAmount(totalAmount + calculatedTip)
    }, [tipPercentage]); // Recalculate if tipPercentage or totalAmount changes

    return (
        <>
            <div style={{ display: "flex", justifyContent: "center" }}>
                <div className='cart-logo'>
                    <img src={restaurant?.logo} className="cart-logo-image" />
                </div>
            </div>

            <div className='cart-main-div-head'>
                <Scrollbars style={{ height: modalHightDivision }} > {/**className='payment-option-scrollbar' */}
                    <div className={currentLanguage?.left_to_right === 0 ? 'cart-head-cross-pay cart-head-cross-pay-rtl' : 'cart-head-cross-pay'}>
                        <h2 className={currentLanguage?.left_to_right === 0 ? 'table-heading pay table-heading-rtl' : 'table-heading pay'}> {t('table')}: 1</h2>
                        <span className='second-modal-cross-svg-style'>
                            <img onClick={closeCartModal} src={cartCrossIcon} />
                        </span>
                    </div>

                    <div className='btn-rounded-border'>
                        <div className="payment-btn-second-payfully-modal" >
                            <button className={currentLanguage?.left_to_right === 0 ? 'payment-button-btn2-payFully payment-btn2-rtl-payfully' : 'payment-button-btn2-payFully'}>
                                {t('splitBill')}
                            </button>
                            <button className={currentLanguage?.left_to_right === 0 ? 'payment-button-btn1-payFully payment-btn1-rtl-payfully ' : 'payment-button-btn1-payFully'}>
                                {t('viewBill')}
                            </button>
                        </div>
                    </div>

                    <div className='tip-div'>
                        <h3 className={currentLanguage?.left_to_right === 0 ? 'tip-heading tip-heading-rtl' : 'tip-heading'}>{t('YourTipsMatter')}</h3>
                    </div>

                    <div className={currentLanguage?.left_to_right === 0 ? 'app-bar-rtl' : ''}>
                        {/* <CounterInput value={tipPercentage} setValue={setTipPercentage}
                        /> */}

                         <AppBar position="static" color='transparent' className={classes.testingTab}>
                            <Tabs
                                value={value} // indicatorColor="primary"
                                textColor="primary"
                                onChange={handleChanges}
                                aria-label="disabled tabs example"
                                variant="scrollable"  // Make the Tabs scrollable
                                scrollButtons="auto"
                                TabIndicatorProps={{
                                    style: { display: 'none' }
                                }}
                            >
                                {tips.map((item, index) => (
                                    <div
                                        key={index}
                                        onClick={() => handleTabClick(index,item.percent)} // Set active tab on click
                                        className={activeTab === index ? 'pecent-div-span' : 'other-tab-div'}
                                    >
                                        <Tab key={index} label={item.percent}
                                            className="tip-value"
                                        />
                                    </div>
                                ))}
                            </Tabs>
                        </AppBar> 
                    </div>

                    <div className={currentLanguage.left_to_right === 0 ? 'tip-amt-rtl tip-amt' : 'tip-amt'} >
                        <h4 className={currentLanguage?.left_to_right === 0 ? 'tip-amt-arabic' : ''}>{t('tipAmt')}</h4>
                        <p>{restaurantSuccess?.data?.currency}. <span className="tip-amt-span">{tipAmount}</span></p>
                    </div>

                    <div className='cart-doted-line-div'>
                        <img className='cart-doted-line-div-img' src={cartDotedLine} />
                    </div>

                    <div className={currentLanguage?.left_to_right === 0 ? 'payable-amount-div-rtl payable-amount-div' : 'payable-amount-div'} >
                        <div style={{ paddingLeft: '25px' }}>
                            <h3 className={currentLanguage?.left_to_right === 0 ? 'paya-head paya-head-rtl' : 'paya-head'}>
                                {t('youRPayingFully')}
                            </h3>
                            <p className={currentLanguage?.left_to_right === 0 ? 'paya-parah-rtl paya-parah' : 'paya-parah'}>
                                {t('tipTaxIncluded')}
                            </p>
                        </div>
                        <div>
                            <span className='paya-price'> {restaurantSuccess?.data?.currency} {payableAmount} </span>
                        </div>
                    </div>

                    <div>
                        <h2 className={currentLanguage?.left_to_right === 0 ? 'payment-method-head payment-method-head-rtl' : 'payment-method-head'} >{t('paymentMethods')}</h2>
                        <div className='payment-method-div'>
                            <RadioGroup onChange={handlePaymentChange} value={paymentValue}>
                                {paymentMethodsObj.map((item, index) => (
                                    <div className='payment-type-div'>
                                        <FormControlLabel className='radio-form-main-pay'
                                            control={<Radio className={classes.radioForm} color='primary' />}
                                            key={index}
                                            value={item._id}  // Set the unique value here
                                            // value={paymentValue[index]}
                                            label={
                                                <span className="flag-inner-div-pay">
                                                    <span className='flg-div-pay'><img src={item.img} /></span>
                                                    <span className={`flag-icon flag-icon-${''}`} style={{ marginRight: 10 }}></span>{item.title}
                                                </span>
                                            }
                                        />
                                    </div>
                                ))}
                            </RadioGroup>
                        </div>
                    </div>

                    <div className={currentLanguage?.left_to_right === 0 ? 'terms-div terms-div-rtl' : 'terms-div'} >
                        <p className={currentLanguage?.left_to_right === 0 ? 'terms-div-rtl' : ''}>
                            {t('tapOnPay')}
                            <span className={currentLanguage?.left_to_right === 0 ? 'terms-div-rtl' : ''} >
                                {t('termsOfUse')}
                            </span>
                        </p>
                        <div className='pay-btn-parent'>
                            <button className={currentLanguage?.left_to_right === 0 ? 'pay-btn-with pay-btn-with-rtl ' : 'pay-btn-with'} onClick={paymentBtn}>
                                 pay with 
                            </button>
                            {/* <button className={currentLanguage?.left_to_right === 0 ? 'pay-btn-with pay-btn-with-rtl ' : 'pay-btn-with'} onClick={paymentBtn}>
                                {t('payWith')} Apple Pay
                            </button> */}
                        </div>
                    </div>
                </Scrollbars >
            </div >
        </>
    )
}
export default PayFully;