import React from 'react';
import { styled } from '@mui/system';
import RemoveIcon from '@mui/icons-material/Remove';
import AddIcon from '@mui/icons-material/Add';

const StyledInputRoot = styled('div')`
  display: flex;
  align-items: center;
`;

const InputContainer = styled('div')`
  position: relative;
  display: inline-block;
`;

const StyledInput = styled('input')`
  font-size: 0.875rem;
  text-align: center;
  width: 4rem;
  padding: 10px 12px;
  padding-right: 20px; // Add extra space for the % symbol
  border: 1px solid #ccc;
  border-radius: 8px;
  margin: 0 8px;
  
  -moz-appearance: textfield;
  appearance: textfield;
  
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

const PercentageSymbol = styled('span')`
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
  font-size: 0.875rem;
  color: #888;
`;

const StyledButton = styled('button')`
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #f3f6f9;
  border: 1px solid #e5eaf2;
  border-radius: 50%;
  cursor: pointer;

  &:hover {
    background: #007fff;
    color: white;
  }
`;

export default function CounterInput({ value, setValue, onValueChange }) {
  const increment = () => {
    setValue((prev) => {
      const newValue = Math.min(prev + 1, 99);
      if (onValueChange) onValueChange('1');
      return newValue;
    });
  };

  const decrement = () => {
    setValue((prev) => {
      const newValue = Math.max(prev - 1, 0);
      if (onValueChange) onValueChange('0');
      return newValue;
    });
  };

  return (
    <StyledInputRoot>
      <StyledButton onClick={decrement}>
        <RemoveIcon fontSize="small" />
      </StyledButton>
      <InputContainer>
        <StyledInput
          readOnly
          type="number"
          value={value}
          onChange={(e) => setValue(Number(e.target.value))}
          min="1"
          max="99"
        />
        <PercentageSymbol>%</PercentageSymbol>
      </InputContainer>
      <StyledButton onClick={increment}>
        <AddIcon fontSize="small" />
      </StyledButton>
    </StyledInputRoot>
  );
}
