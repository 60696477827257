import React, { useState, useEffect, useImperativeHandle, useContext } from 'react';
import { useStyles } from './styles';
import { cart, foodCart } from '../../../assets/images/img'
import { Slide } from 'react-reveal';
import { selectedDishes, RelatedItems, selectedDishesArray, relatedItems } from '../../../environment';
import '../../frontend/scss/cart.scss';
import CartInner from './cartInner';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
// import theme from '../scss/ThemeStyles.scss';
import { ThemeContext } from "../../../ThemeContext";
import { useCart } from '../layout/cartContext'
import { cross } from '../../../assets/images/images';
import { useMediaQuery } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { connect } from "react-redux";
import * as actions from "../../../store/actions";

function Cart(props) {
    const { setOpenModalCart, currentLanguage,setPaymentSuccess, closeOpenModalCart } = props;
    const { t } = useTranslation();
    const classes = useStyles();
    const { cartItems, addItemToCart } = useCart(); // Step 3: Consume the context
    const [cartType,setCartType] = useState(false);

    const [billModal,setBillModal] = useState(true);

    const closeMainCart = () => {
        setBillModal(false);
    }

    useEffect(() => {
        const isModalOpen = localStorage.getItem('cartModal');
        console.log("isModalOpen", isModalOpen);
        // if (isModalOpen === true) {
        function handleClickOutside(event) {
            const clickedInside = event.target.closest('.cart-div');
            if (clickedInside) {
                setOpenModalCart(true);
                // localStorage.setItem('cartModal',false);
            }
            // }
            document.addEventListener('mousedown', handleClickOutside);
            return () => {
                document.removeEventListener('mousedown', handleClickOutside);
            };
        }
    }, []);

    useEffect(() => {
        if (cartItems && currentLanguage)
            fetchCartItems();
    }, [currentLanguage, cartItems])

    const fetchCartItems = () => {
        const orderId = localStorage.getItem('orderId');
        if (orderId)
            props.getCartItems({ order_id: orderId, language_id: currentLanguage._id })
    }

    const history = useHistory();
    const handleProceedToPaymentBtn = () => {
        history.push('./cart-Details')
    };
    
    //code for toggle button
    const [alignment, setAlignment] = React.useState('web');
    const { themeValue } = useContext(ThemeContext);
    
    const handleChange = (event, newAlignment) => {
        setAlignment(newAlignment);
    };
    const handleCloseModal = () => {
        setOpenModalCart(false)
        //  localStorage.setItem('cartModal',false);
    }
    const isMobile = useMediaQuery('(max-width:960px)');

    return (
        <>
        {/* {billModal && */}
        <Slide up> {/* Slide right */}
            <div className='cart-div' data-theme={themeValue}>
                {/* {isMobile && */}
              
                {/* } */}
                {/* <Scrollbars  style={{ height: 490 }}> */}
                {/* {
                cartItems.length > 0 ? ( */}
            
                <CartInner
                    // cartItems={cartItems}
                    setOpenModalCart={setOpenModalCart}
                    setCartType={setCartType}
                    cartType={cartType}
                    setPaymentSuccess={setPaymentSuccess}
                // relatedItems={relatedItems}
                />
            
                {/* ) : ( */}
                {/* // <>
                    //    <div className='cart-outer-btn'>
                    //         <span className='cart-text-btn'>
                    //             <button style={{ cursor: 'pointer' }}>{t('dineIn')}</button>
                    //         </span>
                    //     </div>
                    //     <div className='cart-item-mdd'>
                    //         <div className='cartScrollbar cartimage'>
                    //             <img src={foodCart} alt="Food Cart" />
                    //             <h2 className='hungry-heading'>{t('areYouHungry')}</h2>
                    //             <span className='hungry-para'>{t('youHaveNotAdded')}<br /> {t('toYourCart')}</span>
                    //         </div>
                    //         <div className='btn-payment button-payment-disable'>
                    //             <span>
                    //                 <button>{t('proceedToPayment')}</button>
                    //             </span>
                    //         </div>
                    //     </div>

                    // </> */}
                {/* )} */}
                {/* </Scrollbars> */}
                {
                    // cartItems.length < 0 && (
                    //     <>
                    //         <hr className='dashes-style' />
                    //         <div className='summary-div-main'>
                    //             {/* <span className='left-span-total'>Total Bill</span>
                    //         <span className='right-span-total'>{'BNG 15.00'}</span> */}
                    //         </div>
                    //         <div className='cart-outer btn-payment'>
                    //             <span>
                    //                 <button style={{ cursor: 'pointer' }}>{('Proceed to Payment')}</button>
                    //             </span>
                    //         </div>
                    //         <div className='btn-payment'>
                    //             <button onClick={''} >Proceed to Payment</button>
                    //         </div>
                    //     </>
                    // )
                }
            </div>
        </Slide>
        
        </>
    )
}
// what is needed at start
const mapStateToProps = ({ languageReducer }) => {
    const { currentLanguage } = languageReducer;
    return { currentLanguage };
};
//which actions our function can dispatch
const mapDispatchToProps = (dispatch) => {
    return {
        getCartItems: (data) => dispatch(actions.getCartItemsStart(data)),
    }
};
export default connect(mapStateToProps, mapDispatchToProps)(Cart);