import React, { useState, useEffect } from 'react';
import { Slide } from 'react-reveal';
import {add,minus} from '../../../assets/images/newImages/index.js';
import '../../frontend/scss/cart.scss';
import DeleteIcon from '@mui/icons-material/Delete';

function QuantityHandler (props) {
    //Declarations of state and variables and getting props
    const { quantity, onQuantityChange }  = props;
    const [updatedQuantity,setUpdatedQuantity] = useState(quantity); 

    //add Quantity +1
    const addQty = () => {
        setUpdatedQuantity(prevQuantity => prevQuantity + 1);
    };

    //minus Quantity -1
    const minusQty = () => {
        setUpdatedQuantity(prevQuantity => Math.max(prevQuantity - 1, 1));
    };

    //Send updated quantity to parent whenever it changes
     useEffect(() => {
        onQuantityChange(updatedQuantity);
    }, [updatedQuantity]);

    /**Render App */
    return (
        <div className='quantityHandler-main-class'>
            <Slide>
                <div className='quantityHandler-sub-class'>  
                    { updatedQuantity === 1 ? (
                            <DeleteIcon />
                        ) : (
                            <img src={minus} onClick={minusQty}/>
                        )
                    }   
                    <div className='quantity-value'>
                        {updatedQuantity}
                    </div>
                    <img src={add} onClick={addQty} />
                </div>
            </Slide>
        </div>
    )
}
export default QuantityHandler;